/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.legapro {
  --primary-color: #1064ac;
  --primary-dark-color: #0e5a9a;
  --primary-darker-color: #0c5089;
  --primary-darkest-color: #0b4678;
  --primary-light-color: #2773b4;
  --primary-lighter-color: #3f83bc;
  --primary-lightest-color: #bacddd;

  --accent-color: #c9b780;
  --accent-dark-color: #b4a473;
  --accent-darker-color: #a09266;
  --accent-light-color: #cebe8c;
  --accent-lighter-color: #d3c599;
  --accent-lightest-color: #e7e3d4;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.grid-col-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-col-3 {
  grid-template-columns: repeat(3, 1fr);
}

.gap-10 {
  gap: 10px;
  row-gap: 10px;
  column-gap: 10px;
}

.gap-20 {
  gap: 20px;
  row-gap: 20px;
  column-gap: 20px;
}

.gap-30 {
  gap: 30px;
  row-gap: 30px;
  column-gap: 30px;
}

.gap-40 {
  gap: 40px;
  row-gap: 40px;
  column-gap: 40px;
}

.gap-50 {
  gap: 50px;
  row-gap: 50px;
  column-gap: 50px;
}

.gap-60 {
  gap: 60px;
  row-gap: 60px;
  column-gap: 60px;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.full-height {
  height: 100vh;
}

.full-width,
.full-w {
  width: 100%;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-5 {
  margin-bottom: 5px;
}

.my-20 {
  margin: 20px 0;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.py-20 {
  padding: 20px 0;
}

.pt-10 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.py-10 {
  padding: 10px 0;
}
.pt-30 {
  margin-top: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.py-30 {
  padding: 30px 0;
}

.border-bottom {
  border-bottom: 1px solid var(--gray-lightest-color);
}

.center-cell {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

@media screen and (min-width: 769px) {
  .md-hidden {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .sm-flex {
    display: flex;
  }

  .sm-flex-column {
    flex-direction: column;
  }

  .sm-flex-row {
    flex-direction: row;
  }

  .sm-justify-start {
    justify-content: flex-start;
  }

  .sm-justify-end {
    justify-content: flex-end;
  }

  .sm-justify-center {
    justify-content: center;
  }

  .sm-items-start {
    align-items: flex-start;
  }

  .sm-items-end {
    align-items: flex-end;
  }

  .sm-items-center {
    align-items: center;
  }

  .sm-gap-20 {
    gap: 20px;
    row-gap: 20px;
    column-gap: 20px;
  }

  .sm-hidden {
    display: none;
  }
}

.Toastify__toast--default {
  background: var(--white-color);
}
.Toastify__toast--default .Toastify__progress-bar {
  background-color: var(--black-color);
}

.Toastify__toast--info {
  background: var(--white-color);
}
.Toastify__toast--info .Toastify__progress-bar {
  background-color: var(--info-color);
}

.Toastify__toast--success {
  background: var(--white-color);
}
.Toastify__toast--success .Toastify__progress-bar {
  background-color: var(--success-color);
}

.Toastify__toast--warning {
  background: var(--white-color);
}
.Toastify__toast--warning .Toastify__progress-bar {
  background-color: var(--warning-color);
}

.Toastify__toast--error {
  background: var(--white-color);
}
.Toastify__toast--error .Toastify__progress-bar {
  background-color: var(--danger-color);
}

.Toastify__toast-body {
  color: var(--black-color);
}

.Toastify__toast-body p {
  font-size: var(--m-size);
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
  color: #777;
}

a {
  text-decoration: none !important;
}

.container {
  max-width: 600px;
  margin: 0 auto;
}

button {
  border-radius: 10px !important;
  height: 48px !important;
}

button.auto-height {
  height: auto !important;
}

.block {
  display: block;
  width: 100%;
}
